import {
  Close,
  Delete,
  DeleteOutline,
  ExpandLess,
  ExpandMore,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { NoDataSvg } from "../../../clinicalNotesClinicalRoute/ClinicalNoteSVGs";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../../firebaseConfig";
import { Typography } from "@material-ui/core";
import Cookies from "js-cookie";
import UploadDocumentsfromExperts from "./UploadDocumentsfromExperts";
import { useAuth } from "../../../../../auth";
import { AddDocument, SendUploadedDoc } from "../_request";

function Documents({ clientDetails }) {
  const { enqueueSnackbar } = useSnackbar();
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debounce, setDebounce] = useState("");
  const { currentUser } = useAuth();
  const [fileUploaded, setFileUploaded] = useState("idle");
  const [uploadedFileLink, setUploadedFileLink] = useState(null);
  const storedData = localStorage.getItem("Authorization");
  const token = storedData ? storedData?.split("Bearer ")[1] : null;

  const FetchClinicalNotesClients = async (clientDetails) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_EXPERT_BASE_URL}clinicalnotes/list-prescriptions?count=10&page=${page}&user_id=${clientDetails}&category=${selectedDocument}&search=${debounce}`,
        {
          method: "GET",
          headers: {
            "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
            "Content-Type": "application/json",
            token: token,
            authenticationToken: Cookies.get("authDataJwt"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const resp = await response.json();
      if (resp.status === "success") {
        setDocuments(resp.data);
        setTotalPages(resp.total_pages);
      } else {
        enqueueSnackbar(resp.message, { variant: "info" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (clientDetails) {
      const profile = JSON.parse(localStorage.getItem("profile"));

      FetchClinicalNotesClients(clientDetails);
    }
  }, [clientDetails, page, selectedDocument, debounce]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebounce(searchTerm);
    }, 500);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);

  const DocTypes = {
    png: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/IMG2File.png`,
    doc: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/DOCFile.png`,
    excel: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/EXCELFile.png`,
    pdf: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/PDFFile.png`,
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleUploadExpertDocs = async (file, fileType) => {
    try {
      //const dirName = dayjs().format("MMMM_YYYY");
      const storageRef = ref(
        storage,
        `/clinic-data-storage/clients/${currentUser?.Country}/${dayjs().format(
          "YYYY-MMM-DD_HH:mm:ss"
        )}_${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      setFileUploaded("uploading");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(`Upload progress: ${percent}%`);
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);

            console.log("URL obtained:", url);

            // Local variable to handle logic immediately
            let fileStatus;
            setUploadedFileLink(url);

            if (fileType === "FILE") {
              fileStatus = "uploaded";
              setFileUploaded(fileStatus);
            } else {
              fileStatus = url;
              setFileUploaded(fileStatus);
            }

            console.log(
              "fileUploaded (local):",
              fileStatus,
              "uploadedFileLink:",
              url
            );

            // Use the local variable for logic instead of relying on state
            if (fileStatus === "uploaded") {
              sendDataToSTT(url);
            }
          } catch (error) {
            console.error("Error getting download URL:", error);
          }
        }
      );
    } catch (error) {
      console.error("Error while uploading", error);
      setFileUploaded("idle");

      setUploadedFileLink(null);
    }
  };

  const sendDataToSTT = async (uploadedFileLink) => {
    try {
      const body = {
        image_url: uploadedFileLink,
        lmm_prompt: "",
        llm_prompt: "",
        max_tokens: 0,
        temperature: 0,
      };

      const responseData = await SendUploadedDoc(JSON.stringify(body));

      if (responseData?.status == 200) {
        // setUpdateUploadState({
        //   uploadData: responseData?.data,
        //   uploadState: true,
        // });

        const addBody = {
          uploaded_by: "expert",
          user_id: clientDetails + "",
          creator_id: currentUser?.UserId + "",
          clinical_note_id: null,
          //datetime: "2024-08-16T14:17:23.990717",
          doctor_names: responseData?.data?.doctor_names || "",
          response: responseData?.data?.response,
          clinical_relevance: responseData?.data?.clinical_relevance,
          metadata: responseData?.data?.metadata,
        };

        const addDocResponse = await AddDocument(addBody);

        if (addDocResponse?.data?.status === "success") {
          // await FetchMyDocuments();
          setFileUploaded("idle");
          await FetchClinicalNotesClients(clientDetails);
        }
      }
    } catch (error) {
      // const newMessage = {
      //   _id: uniqueID,
      //   response: "server",
      //   data: "I'm sorry, I ran into a problem while uploading your document. Could you please try uploading again?",
      //   avatar: state.selectedFace,
      // };
      // console.log("sendDataToSTT", newMessage);
      // console.log("newMessage", [...state.chatHistory, newMessage]);
      // const uploadFailedResponse = await sendMessageToApi(
      //   "payload",
      //   "Upload_Failed"
      // );
      console.log("error", error);
    }
  };

  return (
    <div>
      <div className="d-flex my-3 gap-2 align-items-center justify-content-between">
        <Grid container spacing={2}>
          {/* Full-width header */}
          <Grid item xs={12} sm={4}>
            <div className="d-flex align-items-center gap-2">
              <TextField
                sx={{ minWidth: "150px" }}
                placeholder="Search"
                size="small"
                label=""
                value={searchTerm}
                disabled={selectedDocument}
                onChange={(e) => setSearchTerm(e.target.value)}
              />{" "}
              <Box sx={{ minWidth: "100px" }}>
                <FormControl
                  size="small"
                  fullWidth
                  sx={{ background: "white" }}
                  className=""
                >
                  <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                  <Select
                    id="basic-menu"
                    onChange={(e) => {
                      setSelectedDocument(e.target.value);
                    }}
                    label="Filter"
                    value={selectedDocument}
                    disabled={debounce}
                  >
                    <MenuItem value="prescription">Prescription</MenuItem>
                    <MenuItem value="lab test">Lab Test</MenuItem>
                    <MenuItem value="treatment plans">Treatment Plans</MenuItem>
                    <MenuItem value="case history">Case History</MenuItem>
                    <MenuItem value="health monitoring data">
                      Health Monitoring Data
                    </MenuItem>
                    <MenuItem value="medication history">
                      Medication History
                    </MenuItem>
                    <MenuItem value="medical image">Medical Image</MenuItem>
                    <MenuItem value="surgical reports">
                      Surgical Reports
                    </MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Grid>
          {/* Sidebar and Content */}
          <Grid item xs={12} sm={4}>
            {(selectedDocument || debounce) && (
              <div className="d-flex align-items-center gap-2 border rounded-3 p-2">
                {selectedDocument || debounce}

                <Close
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedDocument("");
                    setSearchTerm("");
                  }}
                />
              </div>
            )}
          </Grid>
          {/* Footer */}
          <Grid item xs={12} sm={4} align="right">
            <div>
              <UploadDocumentsfromExperts
                handleUploadExpertDocs={handleUploadExpertDocs}
                fileUploaded={fileUploaded}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ maxWidth: "190px", paddingTop: "5px" }}
              >
                <span style={{ color: "red" }}>*</span>JPG, JPEG, PNG, PDF, up
                to 20MB.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="row">
        {documents.map((item, index) => (
          <div className=" mb-4" key={index}>
            <div className="card">
              <div className="d-flex flex-column flex-md-row gap-2 align-items-center px-md-4 p-2">
                <div className="d-flex align-items-center  w-100  gap-2 gap-md-4 ">
                  <div>
                    <img
                      src={DocTypes[item?.file_type] || DocTypes.png}
                      alt={`${item?.image_url} preview`}
                      className="card-img-top"
                      style={{
                        minWidth: "60px",
                        width: "60px",
                        height: "auto",
                      }} // Set a fixed width and auto height
                    />
                  </div>

                  <div style={{ color: "#344054" }} className="">
                    <span
                      style={{
                        fontSize: "14px",
                        background: "#DCFAE6",
                        color: "#079455",
                        border: "1px solid #079455",
                      }}
                      className="px-2 rounded-pill"
                    >
                      {item?.category_name?.charAt(0)?.toUpperCase() +
                        item?.category_name?.slice(1)}
                    </span>
                    <p className="m-0">
                      <strong>File Name:</strong> {item?.new_file_name}
                    </p>

                    <p className="m-0 ">
                      {dayjs(item?.uploaded_datetime).format(
                        "MMM D, YYYY h:mm A"
                      )}
                    </p>
                  </div>
                </div>

                <Button
                  variant="text"
                  sx={{
                    borderRadius: "20px", // Rounded but not too much
                    bgcolor: "#f5f5f5",
                    color: "#414141",
                    fontSize: "14px", // 14px as in the default theme
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#f5f5f5",
                    },
                  }}
                  className="d-flex justify-content-center align-items-center gap-2 rounded-3 px-3 "
                  onClick={() => {
                    window.open(item?.image_url);
                  }}
                >
                  <Visibility />
                  View
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {documents?.length === 0 && (
        <div className="NoDataAvailable">
          {NoDataSvg}
          <p> No Documents Available</p>
        </div>
      )}
      {documents?.length > 0 && (
        <div className="d-flex justify-content-end mb-5 p-4">
          <Box
            sx={{
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  backgroundColor: "#var(--primary-color)", // Selected page background color
                  color: "#fff", // Selected page text color
                },
                "&:hover": {
                  backgroundColor: "var(--primary-color)", // Hover background color
                },
              },
            }}
          >
            <Pagination
              count={totalPages ? totalPages : 1}
              page={page}
              onChange={handleChange}
              renderItem={(item) => <PaginationItem {...item} />}
            />
          </Box>
        </div>
      )}
    </div>
  );
}

export default Documents;
