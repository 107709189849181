import axios from "axios";
import Cookies from "js-cookie";
export const GetMyDocuments = async (data) => {
  //  console.log("authDataJwt", Cookies.get("authDataJwt"))
  try {
    const headers = {
      Accept: "application/json",
      token: data?.token,
      authenticationToken: Cookies.get("authDataJwt"),
    };

    // Construct the base URL
    let url = `https://session-note.uwc.world/clinicalnotes/list-prescriptions?user_id=${data?.userID}&count=${data?.count}&page=${data?.page}`;

    // Append the category if it is not null
    if (data?.category) {
      url += `&category=${data.category}`;
    }

    //Append the search if it is not empty string
    if (data?.search) {
      url += `&search=${data.search}`;
    }

    const response = await axios.get(url, { headers });

    return response;
  } catch (error) {
    // Handle error here if needed
    console.error("Error fetching documents:", error);
    throw error;
  }
};

export const SendUploadedDoc = async (body) => {
  try {
    const response = await axios.post(
      "https://s2t-v2.uwc.world/process-medical-file",
      body,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46WDlyJDN2ITJ3UXorOHBG",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("SendUploadedDoc API error: " + error);
  }
};

export const AddDocument = async (body) => {
  try {
    const response = await axios.post(
      `https://session-note.uwc.world/clinicalnotes/add-prescription`,
      body,
      {
        headers: {
          token: localStorage.getItem("Authorization")?.split(" ")[1],
          authenticationToken: Cookies.get("authDataJwt"),
          "X-API-Key": process.env.REACT_APP_XAPIKEY,
          DeviceType: "web",
          DeviceId: "123",
          Lat: "1",
          Long: "1",
        },
      }
    );

    return response;
  } catch (error) {}
};
